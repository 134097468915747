import type { DocumentReference } from 'firebase/firestore'
import { updateDoc } from 'firebase/firestore'
import { Component } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { BusinessType } from '../../../../src/types/business'
import type { SuperAdminType } from '../../../../src/types/common'
import type { PoolType } from '../../../../src/types/pools'
import type { StafferType } from '../../../../src/types/staffer'
import { PATHS } from '../../constants/routes'
import { errorToast } from '../../helpers/toast'
import { getBusinessById } from '../../staffers/api/getters/business'
import { getPoolById } from '../../staffers/api/getters/pools'
import { getStafferById } from '../../staffers/api/getters/staffer'
import { getDataFromDocumentRef } from '../../staffers/api/wrappers'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import MenuModal, { ModalTypes } from '../modals/MenuModal'

export const ButtonTypes = {
  BUTTON_DEFAULT: 'defaultGrey',
  BUTTON_GREEN: 'defaultMenu',
  BUTTON_RED: 'dangerMenu',
  BUTTON_WARNING: 'warning',
  BUTTON_BLUE: 'primaryBlue',
}

type Props = {
  businessId: string
  currentLocation: string
  isSuperAdmin: boolean
  superAdmin?: SuperAdminType
  business?: BusinessType
  pool?: PoolType
  selectedCountry: string
} & RouteComponentProps

const onMoveToReferenceCalling = async (stafferId: string) => {
  if (stafferId) {
    try {
      await updateDoc(getStafferById(stafferId), {
        accessStatus: 'calling',
      })

      toast.success('Staffer was moved to the "Reference calling requests" list.')
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
    }
  }
}

const onMoveToCallingList = async ({ stafferId, businessId }: { stafferId?: string; businessId?: string }) => {
  if (stafferId) {
    const stafferRef = getStafferById(stafferId)
    const staffer = await getDataFromDocumentRef(stafferRef)
    if (!staffer) {
      return
    }
    const { callingList } = staffer
    try {
      updateDoc(stafferRef, {
        callingList: !callingList,
      })
      toast.success(
        !callingList ? 'Staffer was moved to the Calling list.' : 'Staffer was removed from the Calling list.'
      )
    } catch (error) {
      errorToast((error as Error).message ?? error)
    }
  }
  if (businessId) {
    const businessRef = getBusinessById(businessId)
    const business = await getDataFromDocumentRef(businessRef)
    if (!business) {
      return
    }
    const { callingList } = business
    try {
      updateDoc(businessRef, {
        callingList: !callingList,
      })
      toast.success(
        !callingList ? 'Business was moved to the Calling list.' : 'Business was removed from the calling list.'
      )
    } catch (error) {
      errorToast((error as Error).message ?? error)
    }
  }
}

const callingListButton = ({ stafferId, businessId }: { stafferId?: string; businessId?: string }) => ({
  customCallback: () => onMoveToCallingList({ stafferId, businessId }),
  buttonType: ButtonTypes.BUTTON_DEFAULT,
  title: 'Add to calling list 📞',
  getCustomTitle: async () => {
    let ref: null | DocumentReference<StafferType> | DocumentReference<BusinessType> = null
    if (businessId) {
      ref = getBusinessById(businessId)
    } else if (stafferId) {
      ref = getStafferById(stafferId)
    }
    if (!ref) {
      return ''
    }
    const entity = await getDataFromDocumentRef(ref)
    const { callingList } = entity || {}
    return !callingList ? 'Add to calling list 📞' : 'Remove from calling list 📞'
  },
})

class MenuLowerButtons extends Component<Props> {
  redirect = (newLocation: string) => {
    const { location, history } = this.props
    if (newLocation !== location.pathname) {
      history.push(newLocation)
    }
  }

  render() {
    const { currentLocation, isSuperAdmin, superAdmin, business, pool, selectedCountry } = this.props

    if (isSuperAdmin && currentLocation.includes('/companies/') && (pool === undefined || business === undefined)) {
      return <BeatLoader color="gray" />
    }

    let buttons: Array<{
      buttonType: string
      customCallback?: () => any
      modalType?: string
      title: string
      getCustomTitle?: () => Promise<string>
    }> = []

    if (isSuperAdmin && currentLocation.includes('/companies/')) {
      const businessId = currentLocation.replace('/companies/', '')
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_BLUE,
          modalType: ModalTypes.CREATE_EDIT_POOL,
          title: pool ? 'Edit pool' : 'Create pool',
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.EDIT_BUSINESS,
          title: 'Edit profile',
        },
        ...(pool
          ? [
              {
                buttonType: ButtonTypes.BUTTON_GREEN,
                modalType: ModalTypes.CREATE_INTERNAL_JOB,
                title: 'Post internal job',
              },
            ]
          : []),
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.CREATE_EXTERNAL_JOB,
          title: `Post ${pool ? 'external job' : 'job'}`,
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.NOTIFY_BUSINESS,
          title: 'Notify business',
        },
        callingListButton({ businessId }),
        {
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.BLOCK_BUSINESS,
          title: 'Block / Unblock',
        },
      ]

      if (business) {
        if (!business.isArchived) {
          buttons.push({
            buttonType: ButtonTypes.BUTTON_DEFAULT,
            modalType: ModalTypes.ARCHIVE_BUSINESS,
            title: 'Archive business',
          })
        } else {
          buttons.push({
            buttonType: ButtonTypes.BUTTON_DEFAULT,
            modalType: ModalTypes.UNARCHIVE_BUSINESS,
            title: 'Unarchive business',
          })
        }
      }
    } else if (isSuperAdmin && currentLocation.includes('/staffers/')) {
      const stafferId = currentLocation.replace('/staffers/', '')
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.PAYMENT_DETAILS,
          title: 'Get payment details',
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.EDIT_STAFFER_PERMISSIONS,
          title: 'Edit permissions',
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.PROMOTE_STAFFER,
          title: 'Promote',
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.DEMOTE_STAFFER,
          title: 'Demote',
        },
        callingListButton({ stafferId }),
      ]
      if (superAdmin && superAdmin.backOfficeAccess) {
        buttons.push({
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.BLOCK_STAFFER,
          title: 'Block / Unblock',
        })
        buttons.push({
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.REMOVE_STAFFER,
          title: 'Remove user',
        })
      }
    } else if (isSuperAdmin && currentLocation.includes('/jobs/')) {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.NOTIFY_JOB,
          title: 'Send notification',
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.EDIT_JOB,
          title: 'Edit job',
        },
        {
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.REMOVE_JOB,
          title: 'Remove job',
        },
      ]
    } else if (isSuperAdmin && currentLocation.includes('/jobs-unpublished/')) {
      if (isSuperAdmin) {
        buttons = [
          {
            buttonType: ButtonTypes.BUTTON_GREEN,
            modalType: ModalTypes.PUBLISH_JOB,
            title: 'Publish job',
          },
        ]
      }
      buttons.push({
        buttonType: ButtonTypes.BUTTON_DEFAULT,
        modalType: ModalTypes.EDIT_UNPUBLISHED_JOB,
        title: 'Edit job',
      })
      buttons.push({
        buttonType: ButtonTypes.BUTTON_RED,
        modalType: ModalTypes.REMOVE_JOB,
        title: 'Remove job',
      })
    } else if (currentLocation.includes('/job-ads/')) {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.EDIT_JOB_AD,
          title: 'Edit job',
        },
        {
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.REMOVE_JOB_AD,
          title: 'Remove job',
        },
        {
          buttonType: ButtonTypes.BUTTON_BLUE,
          modalType: ModalTypes.SHARE_JOB_AD,
          title: 'Share job',
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.NOTIFY_JOB_AD,
          title: 'Send notification',
        },
      ]
    } else if (currentLocation.includes('/unpublished-ads/')) {
      if (isSuperAdmin) {
        buttons = [
          {
            buttonType: ButtonTypes.BUTTON_GREEN,
            modalType: ModalTypes.PUBLISH_JOB_AD,
            title: 'Publish job',
          },
        ]
      }
      buttons.push({
        buttonType: ButtonTypes.BUTTON_DEFAULT,
        modalType: ModalTypes.EDIT_UNPUBLISHED_JOB_AD,
        title: 'Edit job',
      })
      buttons.push({
        buttonType: ButtonTypes.BUTTON_RED,
        modalType: ModalTypes.REMOVE_JOB_AD,
        title: 'Remove job',
      })
    } else if (isSuperAdmin && currentLocation === '/companies') {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.INVITE_BUSINESS,
          title: 'Invite business',
        },
      ]
    } else if (isSuperAdmin && currentLocation === '/staffers') {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.INVITE_STAFFER,
          title: 'Invite staffer',
        },
      ]
    } else if (!isSuperAdmin && currentLocation.includes('/staffers/')) {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.PAYMENT_DETAILS,
          title: 'Get payment details',
        },
      ]
    } else if (isSuperAdmin && currentLocation.includes('/staffer-requests/')) {
      const stafferId = currentLocation.replace('/staffer-requests/', '')
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.GRANT_ACCESS_STAFFER,
          title: 'Grant access',
        },
        {
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.DENY_ACCESS_STAFFER,
          title: 'Deny access',
        },
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.SEND_NOTIFICATION,
          title: 'Send notification',
        },
        {
          customCallback: () => onMoveToReferenceCalling(stafferId),
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          title: 'Reference calling',
        },
      ]
    } else if (isSuperAdmin && currentLocation.includes('/company-requests/')) {
      const businessId = currentLocation.replace('/company-requests/', '')
      buttons = [
        callingListButton({ businessId }),
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.GRANT_ACCESS_COMPANY,
          title: 'Grant access',
        },
        {
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.DENY_ACCESS_COMPANY,
          title: 'Deny access',
        },
      ]
    } else if (PATHS.companyProfile === currentLocation) {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.EDIT_BUSINESS,
          title: 'Edit profile',
        },
      ]
    } else if (!isSuperAdmin && currentLocation.includes('/jobs/')) {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_DEFAULT,
          modalType: ModalTypes.EDIT_JOB,
          title: 'Edit job',
        },
        {
          buttonType: ButtonTypes.BUTTON_RED,
          modalType: ModalTypes.REMOVE_JOB,
          title: 'Remove job',
        },
      ]
    } else if (isSuperAdmin && currentLocation.includes('/regions')) {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.CREATE_REGION,
          title: 'Create region',
        },
      ]
    } else if (isSuperAdmin && currentLocation === '/groups') {
      buttons = [
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.CREATE_GROUP,
          title: 'Create group',
        },
      ]
    } else if (isSuperAdmin && currentLocation.includes('/groups/')) {
      buttons = [
        {
          // customCallback: () => editGroup(groupId),
          modalType: ModalTypes.EDIT_GROUP,
          title: 'Edit group',
          buttonType: ButtonTypes.BUTTON_DEFAULT,
        },
      ]
    }

    // buttons that are visible everywhere
    if (!isSuperAdmin) {
      buttons = [
        ...buttons,
        {
          buttonType: ButtonTypes.BUTTON_GREEN,
          modalType: ModalTypes.CREATE_JOB,
          title: 'Create job',
        },
      ]
    }

    return (
      <div>
        {buttons.map((button, index) => (
          <MenuModal
            key={`${button.modalType}-${index}`}
            currentLocation={currentLocation}
            business={business}
            pool={pool}
            selectedCountry={selectedCountry}
            {...button}
          />
        ))}
      </div>
    )
  }
}

export default withRouter(
  connectFirestore((db, props: Props) => {
    if (props.businessId) {
      return {
        business: getBusinessById(props.businessId),
        pool: getPoolById(props.businessId),
      }
    }
    return {}
  }, MenuLowerButtons)
)
