import { Box, Button, Grid, Tab, Tabs } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import * as R from 'ramda'
import type { ChangeEvent } from 'react'
import { Component, Fragment } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
// @ts-ignore react-firestore-connect has no exported @types/ module
import { connectFirestore } from 'react-firestore-connect'
import type { BusinessType } from '../../../../src/types/business'
import type { Firestore } from '../../../../src/types/firebase'
import type { JobAdType } from '../../../../src/types/jobAds'
import type { EnumJobType } from '../../../../src/types/jobTypes'
import type { JobDraftType, JobType } from '../../../../src/types/jobs'
import type { ManagerType } from '../../../../src/types/manager'
import type { PoolType } from '../../../../src/types/pools'
import { hasPermission } from '../../helpers/permissions'
import { getCurrencyByBusiness } from '../../helpers/regionSelector'
import { getHourlyServiceChargeByCountry } from '../../staffers/api/JobCostInfo'
import { getAllBusinesses } from '../../staffers/api/getters/business.legacy'
import { getJobTypes } from '../../staffers/api/getters/common.legacy'
import {
  getJobAdById,
  getJobDetailById,
  getJobDraftsByBusinessId,
  getUnpublishedDetailById,
} from '../../staffers/api/getters/jobs.legacy'
import { getManagerById } from '../../staffers/api/getters/managers.legacy'
import { getPoolById } from '../../staffers/api/getters/pools.legacy'
import CreateFulltimeJob from '../CreateJobAd/CreateFulltimeJob'
import UpfrontWrapper from '../CreateJobAd/UpfrontWrapper'
import styles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import TabPanel from '../mui/TabPanel'
import type { IntegrationsCtx } from '../providers/IntegrationsProvider'
import TextField from '../textInputField/TextInputField'
import CreateEditJob from './CreateEditJob'
// import { ManagerType } from '../../../../src/types/manager'

type PoolAccessType = 'PRIVATE' | 'PUBLIC' | 'PUBLIC_SINCE' // these are not actually stored anywhere exceçpt the state

type Props = {
  closeModal: () => void
  manager: ManagerType
  businesses?: BusinessType[]
  onSave?: (jobId?: string, isJobAd?: boolean) => any
  //  createFreelanceJob: boolean,
  isAdmin?: boolean
  job?: JobType
  jobAd?: JobAdType
  jobAdId?: string
  jobDrafts?: {
    // string is one of job types for generic drafts, otherwise it can be ID in the future
    [jobId: string]: JobDraftType
  }
  jobId?: string
  jobTypes: { values: Array<EnumJobType> }
  business?: BusinessType
  unpublished: string[]
  businessId: string
  managerId?: string
  pool?: PoolType
  preSelectedPoolAccess: PoolAccessType
  integrationsCtx: IntegrationsCtx
} & RouteComponentProps

type BusinessSelectorState = {
  businessId: string
  isPostVariantDecided: boolean
  createFreelanceJob: boolean
  isJobAd: boolean
  tab: number
  poolAccess?: PoolAccessType
}

// eslint-disable-next-line react/no-multi-comp
class JobVariantSelector extends Component<
  Props & {
    businesses?: Array<BusinessType>
    jobDrafts: Array<JobDraftType>
  },
  BusinessSelectorState
> {
  constructor(
    props: Props & {
      businesses?: Array<BusinessType>
      jobDrafts: Array<JobDraftType>
    }
  ) {
    super(props)
    this.state = {
      businessId: '',
      isPostVariantDecided: !!props.jobId || !!props.jobAdId || props.preSelectedPoolAccess !== 'PUBLIC',
      createFreelanceJob: false,
      isJobAd: false,
      poolAccess: props.preSelectedPoolAccess || undefined,
      tab: props.business?.region?.countryName !== 'Norway' ? 1 : 0,
    }
  }

  onBusinessChange = (event: ChangeEvent<HTMLInputElement>) => {
    const businessId = event.target.value
    this.setState({
      businessId,
    })
  }

  onPressPostJob = () => {
    this.setState({
      isPostVariantDecided: true,
      createFreelanceJob: true,
    })
  }

  onPressPostJobAd = () => {
    this.setState({
      isPostVariantDecided: true,
      isJobAd: true,
    })
  }

  undoVariant = () => {
    this.setState(
      {
        isPostVariantDecided: false,
        isJobAd: false,
      },
      () => {
        const { poolAccess } = this.state
        // if variant selection was skipped undo pool access selection as well
        if (poolAccess && poolAccess !== 'PUBLIC') {
          this.setState({
            poolAccess: undefined,
          })
        }
      }
    )
  }

  changeTab = (event: ChangeEvent<HTMLElement>, tabIndex: number) => {
    this.setState({
      tab: tabIndex,
    })
  }

  setPoolAccess = (poolAccess: PoolAccessType) => () => {
    this.setState(
      {
        poolAccess,
      },
      () => {
        // skip job variant selection if internal, since only regular jobs can be internal for now
        if (poolAccess && poolAccess !== 'PUBLIC') {
          this.setState({
            isPostVariantDecided: true,
            createFreelanceJob: false,
          })
        }
      }
    )
  }

  unsetPoolAccess = () => {
    const { poolAccess } = this.state
    const { pool } = this.props
    if (!poolAccess) {
      return undefined
    }
    return () => {
      this.setState({
        isPostVariantDecided: !!pool,
        poolAccess: pool ? 'PRIVATE' : undefined,
      })
    }
  }

  render() {
    const { business, businesses, closeModal, job, jobAd, jobAdId, jobId, jobDrafts, manager, pool } = this.props
    const { isPostVariantDecided, createFreelanceJob, isJobAd, tab, poolAccess } = this.state

    const { region } = business || ({} as BusinessType)

    const isNorway = region?.countryName === 'Norway'

    let actualBusinessId = this.props.businessId
    if (!actualBusinessId) {
      if (job && job.businessId) {
        actualBusinessId = job.businessId
      } else if (jobAd && jobAd.businessId) {
        actualBusinessId = jobAd.businessId
      } else {
        actualBusinessId = this.state.businessId
      }
    }
    const actualBusiness: BusinessType | null =
      business || (businesses && businesses.find(({ id }) => id === actualBusinessId)) || null

    const currency = actualBusiness ? getCurrencyByBusiness(actualBusiness) : 'NOK'

    if (!isPostVariantDecided) {
      return (
        <Fragment>
          <ModalHeader close={closeModal}>Select job variant</ModalHeader>
          {isNorway && (
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              // @ts-ignore MUI Tabs has strong typed onChange
              onChange={this.changeTab}
              aria-label="Language selection"
            >
              <Tab label="Norsk" />
              <Tab label="English" />
            </Tabs>
          )}
          <Box className={styles.modalContent}>
            <TabPanel value={tab} index={0}>
              {/* lang=no */}
              {!jobId && businesses && !this.props.businessId && (
                <div>
                  <h2>For business</h2>
                  <TextField select value={this.state.businessId} onChange={this.onBusinessChange}>
                    {businesses.map(({ businessName, id }) => (
                      <MenuItem key={id} value={id}>
                        {businessName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              <Grid container spacing={3} alignItems="stretch">
                {actualBusiness && hasPermission(actualBusiness, 'postJobAd') && (
                  <Grid item container xs direction="column" justify="space-between">
                    <Box flexBasis="300px">
                      <h3>Stillingsannonse</h3>
                      Ser du etter nye ansatte?
                      <br />
                      Legg ut en stillingsannonse og spesifiser hva du er ute etter!
                      <br />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://intercom.help/staffers/en/articles/3660032-posting-permanent-job-ads" // TODO: replace with customerly article
                      >
                        Les mer her.
                      </a>
                    </Box>
                    <Box display="flex" my={3}>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        name="postJob"
                        onClick={this.onPressPostJobAd}
                      >
                        Legg ut stillingsannonse
                      </Button>
                    </Box>
                  </Grid>
                )}
                {actualBusiness &&
                  hasPermission(actualBusiness, 'postFreelanceJob') &&
                  (poolAccess === 'PUBLIC' || !poolAccess) && (
                    <Grid item container xs direction="column" justify="space-between">
                      <Box flexBasis="300px">
                        <h3>Dagsvakt</h3>
                        <p>
                          {'For restauranter, barer, hoteller og events som '}
                          trenger dyktig personell for dagen. Du vil motta en faktura for alle timene som blir
                          gjennomført.
                        </p>
                        <br />
                        <p>
                          <em>
                            Staffers tar en serviceavgift på{' '}
                            {getHourlyServiceChargeByCountry(business?.address.country)}
                            ,- {currency} per bookede time.
                          </em>
                        </p>
                      </Box>
                      <Box display="flex" my={3}>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          color="primary"
                          name="postShifts"
                          onClick={this.onPressPostJob}
                        >
                          Legg ut jobb
                        </Button>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              {/* lang=en */}
              {!jobId && businesses && !this.props.businessId && (
                <div>
                  <h2>For business</h2>
                  <TextField select value={this.state.businessId} onChange={this.onBusinessChange}>
                    {businesses.map(({ businessName, id }) => (
                      <MenuItem key={id} value={id}>
                        {businessName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              <Grid container spacing={3} alignItems="stretch">
                {actualBusiness && hasPermission(actualBusiness, 'postJobAd') && (
                  <Grid item container xs direction="column" justify="space-between">
                    <Box flexBasis="300px">
                      <h3>Post a job ad</h3>
                      {'Looking for new employees? '}
                      <br />
                      Post a job ad and specify what you are looking for.
                      <br />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://intercom.help/staffers/en/articles/3660032-posting-permanent-job-ads" // TODO: replace with customerly article
                      >
                        Click here to read more.
                      </a>
                    </Box>
                    <Box display="flex" my={3}>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        name="postJob"
                        onClick={this.onPressPostJobAd}
                      >
                        Post job ad
                      </Button>
                    </Box>
                  </Grid>
                )}
                {actualBusiness &&
                  hasPermission(actualBusiness, 'postFreelanceJob') &&
                  (poolAccess === 'PUBLIC' || !poolAccess) && (
                    <Grid item container xs direction="column" justify="space-between">
                      <Box flexBasis="300px">
                        <h3>Post shifts</h3>
                        <p>
                          {'For restaurants, bars, hotels and events that need qualified staff for the day. '}
                          Staffers Event AS sends an invoice for all hours.
                        </p>
                        <br />
                        <p>
                          <em>
                            Staffers charges a service charge of{' '}
                            {getHourlyServiceChargeByCountry(business?.address.country)}
                            ,- {currency} per booked hour.
                          </em>
                        </p>
                      </Box>
                      <Box display="flex" my={3}>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          color="primary"
                          name="postShifts"
                          onClick={this.onPressPostJob}
                        >
                          Post shifts
                        </Button>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </TabPanel>
          </Box>
        </Fragment>
      )
    }
    // In case we are editing existing job ad
    if (jobAdId) {
      return (
        <CreateFulltimeJob
          businessId={actualBusinessId}
          closeModal={this.props.closeModal}
          jobAd={jobAd}
          jobAdId={jobAdId}
        />
      )
    }
    // In case we are creating new job ad, we need to find out whether it should be paid upfront
    if (isJobAd) {
      return (
        actualBusiness && (
          <UpfrontWrapper
            business={actualBusiness}
            businessId={actualBusinessId}
            closeModal={this.props.closeModal}
            goBack={this.undoVariant}
          />
        )
      )
    }
    return (
      <Fragment>
        {!jobId && businesses && !this.props.businessId && (
          <div>
            <h2>For business</h2>
            <TextField select value={this.state.businessId} onChange={this.onBusinessChange}>
              {businesses.map(({ businessName, id }) => (
                <MenuItem key={id} value={id}>
                  {businessName}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
        {actualBusinessId && (
          <CreateEditJob
            createFreelanceJob={createFreelanceJob}
            manager={manager}
            poolAccess={poolAccess}
            isAdmin={this.props.isAdmin}
            jobId={jobId}
            job={job}
            // @ts-ignore Weird Ramda typing error, can ignore
            jobDrafts={jobDrafts ? R.indexBy(R.prop('id'), jobDrafts) : undefined}
            jobTypes={this.props.jobTypes}
            closeModal={this.props.closeModal}
            cancel={!jobId && !pool ? this.undoVariant : this.props.closeModal}
            hideBackButton={!!pool}
            pool={pool}
            onSave={this.props.onSave}
            managerId={this.props.managerId}
            businessId={actualBusinessId}
            integrationsCtx={this.props.integrationsCtx}
          />
        )}
      </Fragment>
    )
  }
}

const JobVariantSelectorLoader = (props: Props) => {
  const { business, businesses, job, jobAd, jobAdId, jobId, jobDrafts, jobTypes, pool, manager } = props
  if (
    !jobTypes ||
    (!(jobId || jobAdId) && pool === undefined) || // <- loading pool
    (jobId && !job) || // <- Editing job
    (jobAdId && !jobAd) || // <- Editing jobAd
    manager === undefined ||
    (!(jobId || jobAdId) && !businesses && !business) || // <- When posting jobs, wait to load business (company) or businesses (superadmin)
    (!(jobId || jobAdId) && !jobDrafts) // <- When posting jobs, wait to load job drafts
  ) {
    return (
      <Box m={4} display="flex" justifyContent="center">
        <BeatLoader />
      </Box>
    )
  }
  // the ts error here is thrown correctly, but will rather not mess with the obtuse condition here
  // @ts-ignore
  return <JobVariantSelector {...props} />
}

export default connectFirestore((db: Firestore, props: Props, uid: string) => {
  const newProps = {
    jobTypes: getJobTypes(db),
    managerId: props.isAdmin ? undefined : uid,
    manager: props.isAdmin ? null : getManagerById(db, uid),
    isAdmin: !!props.isAdmin,
  }
  if (props.jobId) {
    return {
      job: props.unpublished ? getUnpublishedDetailById(db, props.jobId) : getJobDetailById(db, props.jobId),
      ...newProps,
    }
  }
  if (props.jobAdId) {
    return {
      jobAd: props.unpublished ? getUnpublishedDetailById(db, props.jobAdId) : getJobAdById(db, props.jobAdId),
      ...newProps,
    }
  }
  if (props.business) {
    return {
      jobDrafts: getJobDraftsByBusinessId(db, props.businessId),
      pool: getPoolById(db, props.businessId),
      ...newProps,
    }
  }
  if (props.businessId) {
    return {
      businesses: getAllBusinesses(db),
      jobDrafts: getJobDraftsByBusinessId(db, props.businessId),
      pool: getPoolById(db, props.businessId),
      ...newProps,
    }
  }
  return {
    businesses: getAllBusinesses(db),
    ...newProps,
  }
}, withRouter(JobVariantSelectorLoader))
