import {
  Avatar,
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  Assignment as AssignmentIcon,
  Cancel as CancelIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as R from 'ramda'
import React from 'react'
// @ts-ignore
import AsyncSelect from 'react-select/lib/Async'
// @ts-ignore
import { components } from 'react-select'
import type { BusinessType } from '../../../../src/types/business'
import type { Timestamp } from '../../../../src/types/firebase'
import type { JobType } from '../../../../src/types/jobs'
import type { PermissionsType, PoolJobType, PoolType } from '../../../../src/types/pools'
import { searchText } from '../../staffers/api/firestore/searchText.legacy'
import moment from '../../util/moment'
import ButtonWithDisabledTooltip from '../ButtonWithDisabledTooltip'
import Modal from '../mui/Modal'
import ModalHeader from '../mui/ModalHeader'
import AddStaffer from './AddStaffer'
import type { OptionType } from './CreateEditJob'
import styles from './modals.module.css'

type Props = {
  business: BusinessType
  businessId: string
  createdJob: {
    businessId: string
    description: string
    isFreelanceJob: boolean
    location: { lat: number; lng: number } | null
    timeEnd: Timestamp
    timeStart: Timestamp
    employmentReason: string
    presentStaffers: Array<string>
    salaryCurrency: string
    salaryHourly: string | number
    jobType: string
    options: Array<string>
  }
  disabled: boolean
  disabledReason: string
  goBack: () => void
  hasJobPermission: (
    invite: OptionType,
    jobType: string,
    businessId: string,
    toastRemoved?: boolean,
    usePublicPermissions?: boolean
  ) => boolean
  invites: Array<OptionType>
  invalidFormReasons: { [reason: string]: string }
  isAdmin: boolean
  job?: JobType | PoolJobType
  jobType: string
  connectedPools: PoolType[]
  limitedToInvitedUntil: boolean | Timestamp
  onClose: () => void
  onPressSaveJob: () => void
  removeOption: (id: string) => void
  selectOption: (option: OptionType) => void
  togglelimitedToInvitedUntil: () => void
  recommendedWageDialog: 'open' | 'closed' | 'dismissed'
  setDialogClosed: () => void
  setDialogDismissed: () => void
}
// This needs to be one so there isn't skip between 3 results (initial) to 0 after typing first
// letters
const SEARCH_QUERY_MIN_LENGTH = 1

type State = {
  activeStafferId: string | null
  acceptedWorkAgreements: boolean
}

class JobInvitesExternalModal extends React.Component<Props, State> {
  state = {
    activeStafferId: null,
    acceptedWorkAgreements: false,
  }

  openContractPreview = (stafferId: string) =>
    this.setState({
      activeStafferId: stafferId,
    })

  hideContractModal = () =>
    this.setState({
      activeStafferId: null,
    })

  toggleacceptedWorkAgreements = () =>
    this.setState(({ acceptedWorkAgreements }) => ({
      acceptedWorkAgreements: !acceptedWorkAgreements,
    }))

  render() {
    const { activeStafferId, acceptedWorkAgreements } = this.state
    const {
      business,
      businessId,
      createdJob,
      disabled,
      disabledReason,
      goBack,
      hasJobPermission,
      invites,
      invalidFormReasons,
      isAdmin,
      job,
      jobType,
      limitedToInvitedUntil,
      onClose,
      onPressSaveJob,
      removeOption,
      selectOption,
      togglelimitedToInvitedUntil,
      recommendedWageDialog,
      setDialogClosed,
      setDialogDismissed,
    } = this.props

    const { presentStaffers } = createdJob

    const isSearchable = (searchQuery: string): boolean => searchQuery.length >= SEARCH_QUERY_MIN_LENGTH

    const renderOption = (option: { data: OptionType }) => {
      const { id, label } = option.data
      const { Option } = components
      // This needs to be here because getDefaultOptions is cached and it displays already selected
      const isInvitedAlready = invites.find(({ id: eId }) => eId === id)
      return isInvitedAlready ? null : <Option {...option}>{label}</Option>
    }

    const getDefaultOptions = async () => {
      const { connectedPools } = this.props
      const queryResults: Array<OptionType & { timeEnd: Timestamp }> = []
      const db = firebase.firestore()
      // There's an issue, where we initialize timeEnd with null
      // however according to firebase value type ordering (https://firebase.google.com/docs/firestore/manage-data/data-types#value_type_ordering)
      // nullish values will always have precedence over Date values
      // so we have to fetch arbitrary amount of docs and then sort them manually.
      // I've changed the orderBy to totalShifts, as it's likely the staffer with most worked shifts
      // will also have the latest shifts worked, so this way, we can at least somewhat prevent moot fetches
      // where the top 50 results would be all timeEnd:null in large companies who have a lot of applied
      // staffers without approved hours. We then sort by timeEnd manually later down the line
      // in the actual queryResults array inside this functions and limit (slice) down to top 15 results
      const businessStaffers = isAdmin
        ? db.collection('staffers').orderBy('lastContractSignedAt', 'desc').limit(20)
        : db.collection('business').doc(businessId).collection('staffers').orderBy('totalShifts', 'desc').limit(50)
      const queryDocs = await businessStaffers.get()

      await Promise.all(
        queryDocs.docs.map(async (doc) => {
          const data = doc.data()
          const stafferPermissionsDoc = await db.collection('staffersPermissions').doc(doc.id).get()
          const stafferPermissions = stafferPermissionsDoc.data() as PermissionsType

          const label = isAdmin
            ? `${data.nameFirst} ${data.nameLast}`
            : `${data.stafferInfo.nameFirst} ${data.stafferInfo.nameLast}`
          if (data && (data.stafferInfo || data.nameFirst) && stafferPermissions) {
            queryResults.push({
              label,
              id: doc.id,
              photoUrl: isAdmin ? data.photoUrl : data.stafferInfo.photoUrl,
              stafferPermissions,
              timeEnd: data.timeEnd || data.lastContractSignedAt,
            })
          }
        })
      )

      // Filter out already added staffers and staffers only with permissions and sort them
      const results: Array<OptionType> = queryResults
        .filter(({ id }) => !invites.find(({ id: eId }) => eId === id) && !presentStaffers.includes(id))
        .filter((invite) => hasJobPermission(invite, jobType, businessId, false, true))
        .filter((invite) => !connectedPools || !connectedPools.some((pool) => pool.employees.includes(invite.id)))
        .sort((option1, option2) => option1.label.localeCompare(option2.label))
        // here we perform the actual sort, where the nullish values are sorted to the end
        // and we sort the staffers by the latest shift timeEnd
        // eslint-disable-next-line no-nested-ternary
        .sort((option1, option2) =>
          option1.timeEnd === null ? 1 : option2.timeEnd === null ? -1 : moment(option2.timeEnd).diff(option1.timeEnd)
        )

      return R.uniqBy(({ id }: OptionType) => id)(results).slice(0, 15)
    }

    const getSearchResults = async (searchQueries: string): Promise<OptionType[] | void> => {
      const { connectedPools } = this.props
      try {
        if (!searchQueries) {
          const results = await getDefaultOptions()
          return results
        }
        const queryResults: Array<OptionType> = []
        const queryWords = searchQueries.split(' ')
        // Include initial, whole expression in search as well
        const potentialQueryWords = [searchQueries]
        queryWords.forEach((word) => {
          if (isSearchable(word)) {
            // First include searchterm as written
            potentialQueryWords.push(word)
            // Second include searchterm as lowercase
            potentialQueryWords.push(word.toLowerCase())
            // Third include searchterm with first letter in upper case
            potentialQueryWords.push(word.charAt(0).toUpperCase() + word.substring(1))
          }
        })

        const uniqueQueryWords = [...new Set([...potentialQueryWords])].filter((word) => isSearchable(word))

        const db = firebase.firestore()
        // Superadmin can offer job to any staffer with adequate permissions (filtering @ line 306)
        // to make it easier to test, as well as to hotfix any production issue
        const businessStaffers = isAdmin
          ? db.collection('staffers')
          : db.collection('business').doc(businessId).collection('staffers')

        const queries = [
          ...uniqueQueryWords.map((word) =>
            searchText(businessStaffers, isAdmin ? 'nameFirst' : 'stafferInfo.nameFirst', word)
          ),
          ...uniqueQueryWords.map((word) =>
            searchText(businessStaffers, isAdmin ? 'nameLast' : 'stafferInfo.nameLast', word)
          ),
        ]

        await Promise.all(
          queries.map(async (query) => {
            try {
              const queryDocs = await query.get()
              return Promise.all(
                queryDocs.docs.map(async (doc) => {
                  const data = doc.data()
                  const stafferPermissionsDoc = await db.collection('staffersPermissions').doc(doc.id).get()
                  const stafferPermissions = stafferPermissionsDoc.data() as PermissionsType
                  const label = isAdmin
                    ? `${data.nameFirst} ${data.nameLast}`
                    : `${data.stafferInfo.nameFirst} ${data.stafferInfo.nameLast}`
                  if (data && (data.stafferInfo || data.nameFirst)) {
                    queryResults.push({
                      label,
                      id: doc.id,
                      photoUrl: isAdmin ? data.photoUrl : data.stafferInfo.photoUrl,
                      stafferPermissions,
                    })
                  }
                })
              )
            } catch (error) {
              console.error((error as Error)?.message ?? 'Failed to get staffers')
              return undefined
            }
          })
        )

        // Filter out already added staffers and staffers only with permissions and sort them
        const results = R.compose(
          R.sort((option1: OptionType, option2: OptionType) => option1.label.localeCompare(option2.label)),
          R.filter(
            (invite: OptionType) =>
              hasJobPermission(invite, jobType, businessId, false, true) &&
              !invites.find(({ id: eId }) => eId === invite.id) &&
              !presentStaffers.includes(invite.id) &&
              (!connectedPools || !connectedPools.some((pool) => pool.employees.includes(invite.id)))
          )
        )(queryResults)

        return R.uniqBy(({ id }: OptionType) => id)(results)
      } catch (error) {
        console.error((error as Error)?.message ?? 'Failed to get search results')
        return undefined
      }
    }

    return (
      <React.Fragment>
        <ModalHeader close={onClose} goBack={goBack}>
          Invite previous staffers
        </ModalHeader>
        <Box p={3} style={{ overflowY: 'auto' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 className={styles.subtitle}>Assign Staffers</h2>
            </Grid>
            <Grid item xs={12}>
              <AsyncSelect
                disabled={!jobType}
                components={{
                  Option: renderOption,
                }}
                placeholder={jobType ? 'Search for previous staffers...' : 'Please select a job type first'}
                noOptionsMessage={({ inputValue }: { inputValue: string }) =>
                  isSearchable(inputValue)
                    ? 'No results found. A staffer need to have worked at least one shift at your business before inviting them directly'
                    : 'Please enter a valid name of staffer.'
                }
                styles={{
                  option: (provided: Record<string, string>) => ({
                    ...provided,
                    color: 'black',
                    display: 'flex',
                    'flex-direction': 'row',
                    'align-items': 'center',
                    width: '100%',
                  }),
                }}
                value={null}
                loadOptions={getSearchResults}
                onChange={selectOption}
                isSearchable
                defaultOptions
              />
              {invites && invites.length > 0 && (
                <React.Fragment>
                  <List>
                    <Grid container spacing={3} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      {invites &&
                        invites.map(({ id, photoUrl, label }) => (
                          <Grid item xs={6} key={id}>
                            <ListItem key={id}>
                              <ListItemAvatar>
                                <Avatar alt={label} src={photoUrl} />
                              </ListItemAvatar>
                              <ListItemText primary={label} />
                              <Tooltip title="Open contract preview for this employee">
                                <IconButton
                                  edge="end"
                                  aria-label="Open contract preview"
                                  onClick={() => this.openContractPreview(id)}
                                >
                                  <AssignmentIcon />
                                </IconButton>
                              </Tooltip>
                              {job && job.shifts.every((shift) => shift.staffersConfirmed.includes(id)) ? (
                                <Tooltip title="This staffer has already accepted all shifts of this job">
                                  <div>
                                    <VerifiedUserIcon color="primary" />
                                  </div>
                                </Tooltip>
                              ) : (
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" aria-label="remove" onClick={() => removeOption(id)}>
                                    <CancelIcon color="error" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                          </Grid>
                        ))}
                    </Grid>
                  </List>
                </React.Fragment>
              )}
              <FormControlLabel
                control={
                  <Switch
                    disabled={typeof limitedToInvitedUntil !== 'boolean' && !!limitedToInvitedUntil}
                    checked={!!limitedToInvitedUntil}
                    onChange={togglelimitedToInvitedUntil}
                    color="primary"
                  />
                }
                label="Make this job non-exclusive after 6 hours"
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    disabled={!(invites && invites.length)}
                    checked={!!acceptedWorkAgreements}
                    onChange={this.toggleacceptedWorkAgreements}
                    color="primary"
                  />
                }
                label="I accept the work agreement(s)*"
              />
            </Grid>
            <Grid item container direction="row" justify="center" spacing={2} xs={12}>
              <Grid item>
                <Button id="go-back-btn" onClick={goBack} variant="contained">
                  Go back
                </Button>
              </Grid>
              <Grid item>
                <ButtonWithDisabledTooltip
                  onClick={onPressSaveJob}
                  variant="contained"
                  color="primary"
                  reasons={{
                    ...invalidFormReasons,
                    ...(acceptedWorkAgreements
                      ? {}
                      : { disagreed: 'You need to accept the work agreement(s) before offering the job' }),
                  }}
                  reason={acceptedWorkAgreements ? disabledReason : 'disagreed'}
                  disabled={disabled || !(invites && invites.length) || !acceptedWorkAgreements}
                >
                  {job && job.id ? 'Save job with invites' : 'Post job with invites'}
                </ButtonWithDisabledTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Modal
          isOpen={!!activeStafferId}
          onRequestClose={this.hideContractModal}
          contentLabel="AddStaffer"
          ariaHideApp={false}
        >
          <AddStaffer
            business={business}
            isContractPreview
            job={job || createdJob}
            onAcceptStaffer={this.hideContractModal}
            onClose={this.hideContractModal}
            stafferId={activeStafferId}
          />
        </Modal>
        <Dialog open={recommendedWageDialog === 'open'} onClose={setDialogClosed}>
          <ModalHeader close={setDialogClosed}>Recommended wage</ModalHeader>
          <Box p={2}>
            <Typography>Hey there! 👋</Typography>
            <br />
            <Typography>
              The wage you&apos;ve set for this same-day job posting is below the recommended 220 NOK per hour. 📉 To
              boost your chances of success, consider offering a higher wage. 🚀
            </Typography>{' '}
            <br />
            <Typography>What would you like to do?</Typography>
            <Box py={1} display="flex" alignItems="center">
              <Button variant="contained" color="primary" onClick={setDialogClosed}>
                Go back
              </Button>
              <Box ml={1}>
                <Button onClick={setDialogDismissed} variant="contained">
                  Post anyway
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default JobInvitesExternalModal
