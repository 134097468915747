import type { AddressType } from '../../../../../src/types/common'
import { googlePlacesApiKey } from '../../../config'

export const parseAddressFromDetails = (details: any) => {
  const components = details.address_components
  const getComponentByType = (compType: string): string => {
    const component = components.find((item: any) => item.types.some((itemType: any) => itemType === compType))
    return component ? component.long_name : ''
  }

  const city = getComponentByType('postal_town') || getComponentByType('locality') || getComponentByType('sublocality')
  const country = getComponentByType('country')
  const postalCode = getComponentByType('postal_code')
  const streetName = getComponentByType('route')
  const streetNumber = getComponentByType('street_number')
  const street = [streetName, streetNumber].filter((notEmpty) => notEmpty).join(' ')

  return {
    city,
    country,
    postalCode,
    street,
  }
}

// This is EXPENSIVE method, can result in HIGH firebase costs if used in listings and frequent
// calls! This is ONLY TO BE USED IN DETAILS (i.e. one fetch per screen, not to be used on every
// item in the listing
export const getAddressFromCoordinates = (coordinates: any): Promise<AddressType | null> =>
  // eslint-disable-next-line no-undef
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${coordinates.latitude},${coordinates.longitude}&key=${googlePlacesApiKey}&language=en`
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson && responseJson.results && responseJson.results[0]) {
        const address = parseAddressFromDetails(responseJson.results[0])
        return address
      }
      return null
    })
    .catch((e) => {
      console.warn(e)
      return null
    })

export const getAddressStringFromCoordinates = async (coordinates: any): Promise<string> => {
  // eslint-disable-next-line no-undef
  const address = await getAddressFromCoordinates(coordinates)
  if (address) {
    const { street, postalCode, city, country } = address
    return `${street}, ${postalCode} ${city}, ${country}`
  }
  return ''
}
