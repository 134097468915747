import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import type { CustomerlyContextValues } from 'react-live-chat-customerly'
// eslint-disable-next-line import/no-extraneous-dependencies
import { BeatLoader } from 'react-spinners'
import type {
  ActivityInternalToExternalType,
  ActivityRequestType,
  ActivityType,
  BusinessType,
} from '../../../../src/types/business'
import type { GroupType } from '../../../../src/types/groups'
import type { JobType } from '../../../../src/types/jobs'
import type { ManagerType } from '../../../../src/types/manager'
import type { PoolType } from '../../../../src/types/pools'
import PushInternalShiftToExternal from '../../components/modals/PushInternalShiftToExternal/PushInternalShiftToExternal'
import IntegrationsProvider from '../../components/providers/IntegrationsProvider'
import { sortBusinesses } from '../../helpers'
import { getActivityBadgeCounts } from '../../helpers/jobs'
import styles from './CompanyLayout.module.css'
import Footer from './footer/Footer'
import GroupBusinessSelector from './groupSidebar/GroupBusinessSelector'
import GroupSidebar from './groupSidebar/GroupSidebar'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'

type Props = {
  business?: BusinessType & { id: string }
  businesses?: BusinessType[]
  group?: GroupType
  manager?: ManagerType
  pool?: PoolType | null
  externalJob?: [JobType] | null
  eventJob?: [JobType] | null
  tempJob?: [JobType] | null
  unpublishedEventJob?: [JobType] | null
  unpublishedTempJob?: [JobType] | null
  unseenActivities?: ActivityType[][] // outer index is of businesId in manager.groupBusinesses
  unseenActivitiesCurrentBusiness?: ActivityType[][]
  unresolvedRequestsCurrentBusiness?: ActivityRequestType[][]
  unreadMessagesCurrentBusiness?: number
  unresolvedRequests?: ActivityType[][] // outer index is of businesId in manager.groupBusinesses
  unreadMessagesSum: { [businessId: string]: number }
  unreadMessages: {
    [businessId: string]: {
      [chatId: string]: number
    }
  }
  unseenActivitiesInternalToExternal?: ActivityInternalToExternalType[]
  customerly?: CustomerlyContextValues
}

const CompanyLayout = (Layout: React.ComponentType<Props>) => {
  class ComponentWrapper extends React.Component<Props> {
    getBadgeCount = (businessId: string): number => {
      const { manager, unseenActivities, unresolvedRequests, unreadMessagesSum } = this.props

      if (manager === undefined) {
        return 0
      }

      const businessIds = (manager.groupBusinesses || [manager.businessId]).filter(Boolean) as string[]
      const mapByBusinessId = (list: Array<any>) =>
        list.reduce(
          (map, item, i) => ({
            ...map,
            [businessIds[i]]: item,
          }),
          {}
        )
      const unseenActivitiesByBusiness = mapByBusinessId(unseenActivities || [])
      const unresolvedRequestsByBusiness = mapByBusinessId(unresolvedRequests || [])
      return (
        0 +
        (unseenActivitiesByBusiness[businessId] || []).length +
        (unresolvedRequestsByBusiness[businessId] || []).length +
        (unreadMessagesSum[businessId] || 0)
      )
    }

    componentDidUpdate() {
      const { manager, business, customerly } = this.props
      if (manager && business) {
        const customerlyUser = {
          user_id: manager.userId,
          email: business.email,
          phone: business.phone,
          company: {
            company_id: business.businessId || 'Unknown',
            name: business.businessName,
          },
        }
        customerly?.load(customerlyUser)
      }
    }

    render() {
      const {
        business,
        businesses,
        group,
        eventJob,
        externalJob,
        manager,
        pool,
        tempJob,
        unpublishedEventJob,
        unpublishedTempJob,
        unreadMessages,
        unreadMessagesSum,
        unresolvedRequests,
        unseenActivities,
        unseenActivitiesInternalToExternal,
      } = this.props

      if (
        !business ||
        !manager ||
        pool === undefined ||
        externalJob === undefined ||
        unpublishedTempJob === undefined ||
        unpublishedEventJob === undefined ||
        tempJob === undefined ||
        eventJob === undefined ||
        unresolvedRequests === undefined
      ) {
        return null
      }
      const businessIds = (manager.groupBusinesses || [manager.businessId]).filter(Boolean) as string[]
      const mapByBusinessId = (list: Array<any>) =>
        list.reduce(
          (map, item, i) => ({
            ...map,
            [businessIds[i]]: item,
          }),
          {}
        )
      const unseenActivitiesByBusiness = mapByBusinessId(unseenActivities || [])
      const unresolvedRequestsByBusiness = mapByBusinessId(unresolvedRequests || [])

      // Show sidebar when the group manager is not in group settings
      const showingGroupDashboard =
        (manager.groupId || business.groupId) && global.document.location.pathname.includes('/group')

      const badges = getActivityBadgeCounts([
        ...(unseenActivitiesByBusiness[business.id] || []),
        ...(unresolvedRequestsByBusiness[business.id] || []),
      ])

      const filteredBusinesses = businesses?.filter((b) => manager?.groupBusinesses?.includes(b.businessId)) || []
      const sortedBusinesses = filteredBusinesses.length
        ? sortBusinesses(filteredBusinesses).map((b) => b.businessId)
        : [manager.businessId || '']

      // Place all problematic ReactTooltips into this render method to render correctly
      return (
        <div className={styles.wrapper}>
          {business.groupId && (
            <div className={styles.groupSelector}>
              <GroupBusinessSelector
                key={business.id} // force remount when manager changes business
                manager={manager as ManagerType & { id: string }}
                getBadgeCount={this.getBadgeCount}
                sortedBusinesses={sortedBusinesses || []}
              />
            </div>
          )}
          <IntegrationsProvider businessId={business.id}>
            {group && showingGroupDashboard && (
              <div className={styles.groupSidebar}>
                <GroupSidebar
                  business={business}
                  key={business.id} // force remount when manager changes business
                  group={group}
                  pool={pool}
                />
              </div>
            )}
            {!showingGroupDashboard && (
              <div className={styles.sidebar}>
                <Sidebar
                  key={business.id} // force remount when manager changes business
                  pool={pool}
                  business={business}
                  externalJob={externalJob}
                  tempJob={tempJob && tempJob.length > 0 ? tempJob : unpublishedTempJob}
                  eventJob={eventJob && eventJob.length > 0 ? eventJob : unpublishedEventJob}
                  unreadMessages={unreadMessagesSum[business.id]}
                  unseenTemporary={badges.unseenTemporary}
                  unseenFreelance={badges.unseenFreelance}
                  unseenJobAds={badges.unseenJobAds}
                  unresolvedTempJobs={badges.unresolvedTempJobs}
                  unresolvedFreelanceJobs={badges.unresolvedFreelanceJobs}
                  unseenTemporaryCancellations={badges.unseenTemporaryCancellations}
                  unseenFreelanceCancellations={badges.unseenFreelanceCancellations}
                />
              </div>
            )}
            <div className={styles.body}>
              {!showingGroupDashboard && (
                <Header
                  key={business.id} // force remount when manager changes business
                  // @ts-ignore property externalJob does exist on Header :shrug:
                  externalJob={externalJob} // eslint-disable-line react/jsx-props-no-multi-spaces
                  business={business}
                  managerEmail={manager.email}
                  unreadMessages={unreadMessages[business.id]}
                  unreadMessagesSum={unreadMessagesSum[business.id]}
                />
              )}
              <div className={styles.content}>
                {manager.businessId === business.id ? (
                  <Layout
                    {...this.props}
                    key={business.id}
                    unseenActivitiesCurrentBusiness={unseenActivitiesByBusiness[business.id] || []}
                    unresolvedRequestsCurrentBusiness={unresolvedRequestsByBusiness[business.id] || []}
                    unreadMessagesCurrentBusiness={unreadMessagesSum[business.id]}
                  />
                ) : (
                  // show loader when switching businesses for group manager
                  <BeatLoader />
                )}
              </div>
              {unseenActivitiesInternalToExternal &&
                unseenActivitiesInternalToExternal?.map((activity: ActivityInternalToExternalType, index) => (
                  <PushInternalShiftToExternal
                    key={index}
                    jobType={activity.jobType}
                    businessId={business.id}
                    jobId={activity.jobId}
                    business={business}
                    manager={manager}
                    shifts={activity.shifts}
                    customLabel={activity.customLabel}
                    description={activity.description}
                  />
                ))}
              <Footer />
            </div>
          </IntegrationsProvider>
        </div>
      )
    }
  }
  return ComponentWrapper
}

export default CompanyLayout
