import type { BusinessType } from '../../../../../src/types/business'
import type { Firestore, PossibleFirestores } from '../../../../../src/types/firebase'
import type { PermissionsType, PoolType } from '../../../../../src/types/pools'
import { findStafferSkillLevel } from '../../../helpers/staffers'
import { getBusinessByIdPromise } from './business.legacy'
import { getManagerByIdPromise } from './managers.legacy'
import { getStafferPermissions } from './staffer.legacy'

export const getPoolById = (db: PossibleFirestores, poolId: string) => db.collection('pools').doc(poolId)

export const getConnectedPools = async (db: PossibleFirestores, businessId: string): Promise<PoolType[]> => {
  const poolRef = getPoolById(db, businessId)
  const pool = await poolRef.get()
  const poolData = pool.data() as PoolType | undefined
  if (!poolData) {
    return []
  }
  const connectedPoolsData = (
    await Promise.all([...poolData.connectedBusinesses.map((id) => getPoolById(db, id).get())])
  )
    .filter((connectedPool) => connectedPool.exists)
    .map((connectedPool): PoolType => connectedPool.data() as PoolType)
  return [poolData, ...connectedPoolsData]
}

export const getConnectedPoolsByManagerId = async (db: Firestore, managerId: string) => {
  const managerData = await getManagerByIdPromise(db, managerId)
  return managerData && managerData.businessId ? getConnectedPools(db, managerData.businessId) : []
}

export const getAllPools = (db: Firestore) => db.collection('pools')

export const getPoolByManagerId = async (db: Firestore, userId: string) => {
  const managerData = await getManagerByIdPromise(db, userId)
  return managerData && managerData.businessId ? getPoolById(db, managerData.businessId) : []
}

export const getInvitedStafferByCode = (db: Firestore, code: string) => db.collection('staffersInvites').doc(code)

export const getInvitedStaffersByPhone = (db: Firestore, phone: string) =>
  db.collection('staffersInvites').where('used', '==', false).where('phone', '==', phone)

export const getFavoritedStaffersByUsedId = (db: Firestore, id: string) =>
  db.collection('staffersInvites').where('usedBy', '==', id)

export const getInvitedStaffersByPoolId = (db: Firestore, poolId: string) =>
  db.collection('staffersInvites').where('poolId', '==', poolId)

export const getPendingInvitedStaffersByPoolId = async (db: Firestore, poolId: string) =>
  db.collection('staffersInvites').where('poolId', '==', poolId).where('used', '==', false)

export const getStafferSkillLevel = async (
  db: Firestore,
  stafferId: string,
  businessId: string
): Promise<{ [position: string]: string }> => {
  const connectedPools = await getConnectedPools(db, businessId)
  const stafferPermissionsDoc = await getStafferPermissions(db, stafferId).get()
  const stafferPermissions = stafferPermissionsDoc.data() as PermissionsType
  if (connectedPools && connectedPools.length && stafferPermissions && stafferPermissions.businessData) {
    return findStafferSkillLevel(connectedPools, stafferPermissions)
  }
  return {}
}

export const getCustomWage = async (db: Firestore, stafferId: string, businessId: string): Promise<number | null> => {
  const stafferPermissionsDoc = await getStafferPermissions(db, stafferId).get()
  const stafferPermissions = stafferPermissionsDoc.data()
  if (stafferPermissions && stafferPermissions.businessData) {
    const firstBusinessIndex = Object.keys(stafferPermissions.businessData).length - 1
    const firstBusinessId = Object.keys(stafferPermissions.businessData)[firstBusinessIndex]
    if (stafferPermissions.businessData[businessId]) {
      return stafferPermissions.businessData[businessId].customWage || null
    }
    return stafferPermissions.businessData[firstBusinessId].customWage || null
  }
  return null
}

export const getConnectedBusinesses = async (
  db: Firestore,
  businessId: string
): Promise<Array<BusinessType | null>> => {
  const connectedPools = await getConnectedPools(db, businessId)
  return Promise.all(connectedPools.map(async (pool) => getBusinessByIdPromise(db, pool.businessId)))
}

export const getPoolsByEmployeeId = (db: Firestore, stafferId: string) =>
  db.collection('pools').where('employees', 'array-contains', stafferId)

export const getEmployeesInPoolsOf = async (db: Firestore, businessId: string): Promise<Array<string>> => {
  const stafferIds: Set<string> = new Set()

  const pool = await getPoolById(db, businessId).get()
  const poolData = pool.data() as PoolType | undefined
  if (!poolData) {
    return []
  }
  const { employees = [] } = poolData
  employees.forEach((stafferId) => {
    stafferIds.add(stafferId)
  })

  if (poolData) {
    // check connected pools
    await Promise.all(
      poolData.connectedBusinesses.map(async (connectedBusinessId) => {
        const connectedPool = await getPoolById(db, connectedBusinessId).get()
        const connectedPoolData = connectedPool.data() as PoolType | undefined
        const { employees: connectedEmployees = [] } = connectedPoolData || {}
        connectedEmployees.forEach((stafferId) => {
          stafferIds.add(stafferId)
        })
      })
    )
  }
  return [...stafferIds]
}

export const getUsedInvitedStaffersByPhone = (db: Firestore, phone: string) =>
  db.collection('staffersInvites').where('used', '==', true).where('phone', '==', phone).limit(1)

export const getPoolIntegrationEmployees = (db: Firestore, poolId: string, integrationName: string) =>
  db.collection(`pools/${poolId}/integrations/${integrationName}/employees`)

export const getPoolIntegration = (db: Firestore, poolId: string, integrationName: string) =>
  db.doc(`pools/${poolId}/integrations/${integrationName}`)

export const getExistingInviteByUsedBy = async (db: Firestore, usedBy: string) => {
  const inviteDocs = await db.collection('staffersInvites').where('usedBy', '==', usedBy).get()
  return inviteDocs.docs.length ? inviteDocs.docs[0].data() : null
}

export const getFavoriteInvitedStaffers = async (db: Firestore, favoritedBy: string) =>
  db.collection('staffersInvites').where('favoritedBy', '==', favoritedBy).where('used', '==', false)
