import React, { useEffect } from 'react'
import type { CustomerlyContextValues } from 'react-live-chat-customerly'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCustomerly } from 'react-live-chat-customerly'

const helperRef = React.createRef() as React.MutableRefObject<CustomerlyContextValues | null>

const WrappedCustomerly = (props: { innerRef: any }) => {
  const customerly = useCustomerly()
  useEffect(() => {
    if (!props.innerRef.current) {
      props.innerRef.current = customerly
    }
  }, [props.innerRef.current])
  return null
}

const CustomerlyWithRef = () => <WrappedCustomerly innerRef={helperRef} />

const customerlyAPI = () => {
  if (!helperRef.current) {
    return false
  }
  const customerly = helperRef.current
  return customerly as CustomerlyContextValues
}

export { CustomerlyWithRef as Customerly, customerlyAPI }
